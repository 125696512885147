<template>
    <div>
        <NavigationBar/>

        <div class="fs-single-product">
             <div class="fs-page-header">             
                <router-link to="/apps/epsilonsingularlogic/" > Epsilon-SingularLogic</router-link>
                <h1> {{ $route.params.title }}</h1>
            </div>

            <div class="fs-product-info">
                <div class="fs-product-gallery">
                    <div v-for="item in currentProduct.photos" :key="item.id">
                        <img :src="require('../assets/' + item.source)" :alt="item.name">
                    </div>
                </div>
                <div class="fs-product-details">
                    <div class="fs-product-details-header">
                        <p class="fs-product-details-text underline">{{ currentProduct.description }}</p>
                        <span class="fs-product-details-tag">{{ currentProduct.type }}</span>
                    </div>

                    <ul class="fs-product-featurelist">
                        <li v-for="item in currentProduct.features" :key="item.id">
                            <span>{{ item.description }}</span>
                        </li>
                    </ul>
                </div>
            </div>

        </div>
     
    </div>
</template>

<script>

    import NavigationBar from '@/components/NavigationBar.vue';
    import appData from '@/assets/data/EpsilonSingularLogic.json';

    export default {
       name: 'SingleProduct',
       components: {
                NavigationBar
                   },
       data: function() {
           return {             
               appData: appData
           }
       },
       computed: {
        currentProduct: function () {

            var currentProductName = this.$route.params.title;

            var currentProductRow = this.appData.find((productRow) => productRow.name == currentProductName)

            return currentProductRow
        }
        },
       props: {
           product : Object
       }
    }
</script>

<style scoped>
.fs-page-header {
    width:100%;
    background-image:url("../assets/heroBackground.jpeg");
    background-size:cover;
    background-position: center center;
    background-attachment: fixed;
    padding:var(--container-padding);
}

.fs-single-product {
    min-height:55rem;
}

.fs-page-header a {
    text-decoration: none;
    font-size: .95rem;
    margin: 1rem 0;
    color: white;
}

.fs-page-header h1 {
    color: white;
}

.fs-product-info {
    padding:var(--container-padding);
    display: flex;
    background:url(../assets/productBackground.jpg);
    background-size:cover;
}

.fs-product-gallery {
    flex: none;
    width:40%;
    padding-right:var(--container-padding);
}

.fs-product-details {
    flex: auto;
}

.fs-product-details-header {
    min-height:5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap:3rem;
}

.fs-product-gallery img {
    max-width:100%;
    width:auto;
    aspect-ratio: 1;
    margin-bottom:2rem;
}

.fs-product-details-tag {
    background:var(--linear-gradient);
    padding:1rem;
    color:white;
    font-size:.875rem;
   
}

.fs-product-details-text {
    font-size:1.85rem;
    color:var(--text-color-primary);
}

.fs-product-featurelist {
    padding: .5rem 1.55rem .5rem 1.55rem;
    margin-top:2rem;
    list-style:none;
    background: white;
    border: 2px solid;
    border-image-slice: 1;
    border-image-source: var(--linear-gradient-top);
}

.fs-product-featurelist li {
    padding:.75rem 0;
    color:var(--text-color-secondary);
}

.fs-product-featurelist li:before { content: '✔'; margin-left: -10px; margin-right: 10px; } 

@media screen and (max-width:992px) {
    .fs-product-info {
        flex-direction: column;
    }

    .fs-product-gallery {
        margin-top:5rem;
        order:2;
        width: 100%;
    }

    .fs-product-details {
        width:100%;
    }
}

</style>